import {useLocation, useNavigate} from "react-router-dom";

const SimpleDashboardMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const currentRoute = location.pathname;
    //console.log("Current route", currentRoute);

    let user = JSON.parse(localStorage.getItem("user"));

    const playBtnClick = (e) => {
        navigate("/");
    };

    const goToPage = (page) => {
        navigate(page);
    }

    return (
        <div>
            <div className="flex-1 bg-white rounded-[60px] min-w-[400px] mb-10 h-[500px] shadow-lg flex-shrink-0">

                <div
                    className="flex justify-center items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
                    <img src="/img/logo.png" className="w-[150px]" alt="big-logo"></img>
                </div>

                <div className="px-4 py-4">
                    <div className="flex justify-between">
                        <div className="flex">
                            <img className="rounded-[15px]" src={user.profile_photo_url}
                                 alt={"Profile Photo"}></img>
                            <div className="flex flex-col pl-2 mt-2">
                                            <span
                                                className="font-sans font-bold text-[#67240e]">{user.name.substring(0, 9)}</span>
                                <span className="text-[#916151]">Novato</span>
                            </div>
                        </div>

                        <div className="flex items-center gap-2">
                            <button className="hover:bg-[#185691] bg-[#2778c4] text-white rounded-[15px] px-4 py-2"
                                    onClick={playBtnClick}>Jogar
                            </button>
                            <button className="hover:bg-[#77331d] bg-[#ab4827] text-white rounded-[15px] px-4 py-2">Sair
                            </button>
                        </div>
                    </div>
                </div>

                <div className="bg-[#67240e] w-full mb-2 py-[0.1px]"></div>

                <div className="flex flex-col px-4 py-4 gap-2">

                    <ul className="space-y-2">
                        {/* Home page */}
                        <li className="flex justify-between h-[40px] cursor-pointer" onClick={() => goToPage("../dashboard/home")}>
                            <div className="w-1/6 rounded-l-md bg-[#67240e99] text-[#4f2618]">
                                <div className="flex justify-center py-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"/>
                                    </svg>
                                </div>
                            </div>
                            <div className={`flex w-5/6 ${currentRoute === "/dashboard/home" ? "bg-[#a37b6e]" : "bg-[#834835]"} hover:bg-[#a37b6e] px-2 items-center text-white font-bold uppercase rounded-r`}>
                                Página Inicial
                            </div>
                        </li>

                        {/* Account */}
                        <li className="flex justify-between h-[40px] cursor-pointer" onClick={() => goToPage("../dashboard/account")}>
                            <div className="w-1/6 rounded-l-md bg-[#67240e99] text-[#4f2618]">
                                <div className="flex justify-center py-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"/>
                                    </svg>
                                </div>
                            </div>
                            <div
                                className={`flex w-5/6 ${currentRoute === "/dashboard/account" ? "bg-[#a37b6e]" : "bg-[#834835]"} hover:bg-[#a37b6e] px-2 items-center text-white font-bold uppercase rounded-r`}>
                                Conta
                            </div>
                        </li>

                        {/* Wallet */}
                        <li className="flex justify-between h-[40px] cursor-pointer" onClick={() => goToPage("../dashboard/wallet")}>
                            <div className="w-1/6 rounded-l-md bg-[#67240e99] text-[#4f2618]">
                                <div className="flex justify-center py-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"/>
                                    </svg>
                                </div>
                            </div>
                            <div
                                className={`flex w-5/6 ${currentRoute === "/dashboard/wallet" ? "bg-[#a37b6e]" : "bg-[#834835]"} hover:bg-[#a37b6e] px-2 items-center text-white font-bold uppercase rounded-r`}>
                                Carteira
                            </div>
                        </li>

                        {/* Messages */}
                        {/*<li className="flex justify-between h-[40px] cursor-pointer" onClick={() => goToPage("../dashboard/messages")}>*/}
                        {/*    <div className="w-1/6 rounded-l-md bg-[#67240e99] text-[#4f2618]">*/}
                        {/*        <div className="flex justify-center py-2">*/}
                        {/*            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
                        {/*                 strokeWidth="1.5" stroke="currentColor" className="size-6">*/}
                        {/*                <path strokeLinecap="round" strokeLinejoin="round"*/}
                        {/*                      d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"/>*/}
                        {/*            </svg>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className={`flex w-5/6 ${currentRoute === "/dashboard/messages" ? "bg-[#a37b6e]" : "bg-[#834835]"} hover:bg-[#a37b6e] px-2 items-center text-white font-bold uppercase rounded-r`}>*/}
                        {/*        Mensagens*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                        <li className="flex justify-between h-[40px] cursor-pointer" onClick={() => goToPage("../dashboard/affiliates")}>
                            <div className="w-1/6 rounded-l-md bg-[#67240e99] text-[#4f2618]">
                                <div className="flex justify-center py-2">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                        
                                    className="size-6">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <path d="M5.931 6.936l1.275 4.249m5.607 5.609l4.251 1.275M11.683 12.317l5.759-5.759" />
                                    <path d="M7 5.5 A1.5 1.5 0 0 1 5.5 7 A1.5 1.5 0 0 1 4 5.5 A1.5 1.5 0 0 1 7 5.5 z" />
                                    <path d="M20 5.5 A1.5 1.5 0 0 1 18.5 7 A1.5 1.5 0 0 1 17 5.5 A1.5 1.5 0 0 1 20 5.5 z" />
                                    <path d="M20 18.5 A1.5 1.5 0 0 1 18.5 20 A1.5 1.5 0 0 1 17 18.5 A1.5 1.5 0 0 1 20 18.5 z" />
                                    <path d="M13 15.5 A4.5 4.5 0 0 1 8.5 20 A4.5 4.5 0 0 1 4 15.5 A4.5 4.5 0 0 1 13 15.5 z" />
                                    </svg>
                                </div>
                            </div>
                            <div
                                className={`flex w-5/6 ${currentRoute === "/dashboard/affiliates" ? "bg-[#a37b6e]" : "bg-[#834835]"} hover:bg-[#a37b6e] px-2 items-center text-white font-bold uppercase rounded-r`}>
                                Afiliados
                            </div>
                        </li>

                        {/* Buy money */}
                        <li className="flex justify-between h-[40px] cursor-pointer"
                            onClick={() => goToPage("../dashboard/buy-ddt-money")}>
                            <div className="w-1/6 rounded-l-md bg-[#67240e99] text-[#4f2618]">
                                <div className="flex justify-center py-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"/>
                                    </svg>
                                </div>
                            </div>
                            <div
                                className={`flex w-5/6 ${currentRoute === "/dashboard/buy-ddt-money" ? "bg-[#a37b6e]" : "bg-[#834835]"} hover:bg-[#a37b6e] px-2 items-center text-white font-bold uppercase rounded-r`}>
                                Comprar Cupons
                            </div>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    );
};

export default SimpleDashboardMenu;